import { BUS_USER_CONNECTION, BUS_USER_INFO } from '../../common/auth/bus-topics';
import { SgwtWidgetHTMLElement } from '../../common/html-custom-element';
import { SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { executeWhenCustomElementIsPresent, isProductionEnvironment } from '../../common/sgwt-widgets-utils';
import { init, apmBase, ApmBase } from '@elastic/apm-rum';

declare global {
  interface Window {
    _paq?: any; // [string, ...any[]][];
  }
}

interface HTMLSgwtMiniFooterElement extends HTMLElement {
  getUserConsentOnMatomo: () => Promise<boolean>;
}

const MATOMO_URL_HOMOLOGATION = 'https://sg-analytics-uat.fr.world.socgen/piwik';
const MATOMO_URL_PRODUCTION = 'https://t-log.sgmarkets.com';

type trackType = 'trackEvent' | 'trackLink' | 'trackSiteSearch' | 'trackPageView';

export interface SgwtWebAnalyticsPublicFields {
  trackEvent: (category: string, action: string, name?: string, value?: string) => void;
  trackSearch: (keyword: string, category: string, count: number) => void;
  trackLink: (href: string, linkType?: string) => void;
  trackPageView: (href?: string, title?: string) => void;
}

type ApmRumConfiguration = {
  apmRumEnabled: boolean;
  apmRumServerUrl: string;
  apmRumServiceName: string;
  apmRumEnvironment: string;
};

export type SgwtWebAnalyticsConfiguration = {
  baseUrl: string;
  disabled: boolean;
  disableUserTracking: boolean;
  environment: string;
  siteId: number;
  userId: string;
} & ApmRumConfiguration;

// List of attributes of the widget that are used for the Analytics tracker.
const ANALYTICS_ATTRIBUTES = [
  'base-url',
  'disable-user-tracking',
  'environment',
  'site-id',
  'user-id',
  'apm-rum-enabled',
  'apm-rum-service-name',
  'apm-rum-server-url',
  'apm-rum-environment',
];
// List of attributes of the widget specific for this widget.
const ADDITIONAL_ATTRIBUTES = ['debug', 'disabled'];

export class SgwtWebAnalytics extends SgwtWidgetHTMLElement {
  public static is = 'sgwt-web-analytics';
  private configuration: Partial<SgwtWebAnalyticsConfiguration> = {};
  private __currentHref = window.location.href;
  private __apm: ApmBase | undefined = undefined;

  // ---------------------------------------------------------------
  // CUSTOM ELEMENT LIFECYCLE METHODS
  // ---------------------------------------------------------------

  constructor() {
    super(SgwtWidgetName.WEB_ANALYTICS, ANALYTICS_ATTRIBUTES, ADDITIONAL_ATTRIBUTES);
  }

  /**
   * List of attributes we "listen" for this widget. It's essentially the ones for sgwtConnect library.
   */
  static get observedAttributes() {
    return [...ANALYTICS_ATTRIBUTES, ...ADDITIONAL_ATTRIBUTES];
  }

  // public connectedCallback() {}
  // public disconnectedCallback() {}

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    super.attributeChangedCallback(name, oldValue, newValue); // !important
    // Handle attributes that are specific to tracker (all are string except 'disable-user-tracking').
    if (ANALYTICS_ATTRIBUTES.includes(name)) {
      if (name === 'disable-user-tracking') {
        this.configuration.disableUserTracking = newValue === '' || newValue === 'true';
      } else if (name === 'apm-rum-enabled') {
        this.configuration.apmRumEnabled = newValue === '' || newValue === 'true';
      } else {
        (this.configuration as any)[this.convertHyphenToCamelCase(name)] = newValue;
      }
    }
    // Handle attributes that are specific to the widget (all are boolean).
    if (name === 'disabled') {
      this.configuration.disabled = newValue === '' || newValue === 'true';
    }
  }

  // Now we have all the required attributes to setup the Analytics tracker!
  initializeWidget(): void {
    if (!!this.configuration.disabled) {
      this.logDebug('Web analytics is disabled');
      return;
    }

    const { environment, siteId, apmRumServerUrl, apmRumServiceName, apmRumEnabled, apmRumEnvironment } =
      this.configuration;

    if (!!siteId) {
      const prod = !!environment
        ? ['prod', 'production'].includes(environment.toLowerCase())
        : isProductionEnvironment();
      this.logDebug(
        `web analytics initialization on ${prod ? 'production' : 'homologation'} environment with ID ${siteId}.`,
      );
      this.__addTracker(prod);
    } else {
      this.logDebug('No site-id defined. Web analytics is disabled.');
    }

    if (apmRumEnabled) {
      this.logDebug('Initialize APM RUM');
      this.__apm = this.__initApmRum(apmRumServiceName, apmRumServerUrl, apmRumEnvironment);
    }
  }

  // ---------------------------------------------------------------
  // PRIVATE API
  // ---------------------------------------------------------------

  // Push instruction in the Matomo `_paq` object
  private __pushInstruction(name: string, ...args: any[]): void {
    if (!window._paq) {
      this.logDebug(`cannot push instruction "${name}" as the Matomo tracker is not initialized.`);
      return;
    }
    window._paq.push([name, ...args]);
  }

  // React on the modification of the HREF page
  private __observePageChange(): void {
    // TODO check if body is present, otherwise do on onload event.
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((_mutation) => {
        if (this.__currentHref !== document.location.href) {
          this.logDebug(`[observer] location changed from ${this.__currentHref} to ${document.location.href}.`);
          this.__currentHref = document.location.href;
          this.trackPageView();
          this.__apm?.startTransaction(document.location.pathname, 'route-change', { managed: true });
        }
      });
    });
    observer.observe(document.querySelector('body')!, { childList: true, subtree: true });
  }

  private __anonymousTracking(): void {
    this.__pushInstruction('forgetCookieConsentGiven');
    this.__pushInstruction('requireCookieConsent');
  }

  private __getUserId(): string | null {
    if (!!this.configuration.userId) {
      return this.configuration.userId;
    }
    const bus = this.getWidgetsBus();
    const userConnection = bus?.dangerouslyGetCurrentValue<any>(BUS_USER_CONNECTION);
    if (!!userConnection?.mail) {
      return userConnection.mail;
    }
    const userInfo = bus?.dangerouslyGetCurrentValue<any>(BUS_USER_INFO);
    if (!!userInfo?.mail) {
      return userInfo.mail;
    }
    return null;
  }

  private __trackUser(): void {
    // Allow Matomo to use cookies for 6 months (4320 hours).
    this.__pushInstruction('rememberCookieConsentGiven', 4320);
    // Add a custom dimension for SG Monitoring.
    this.__pushInstruction('setCustomDimension', 10, true);
    // Set User ID...
    const userId = this.__getUserId();
    if (userId) {
      this.logDebug(`Track user ${userId}`);
      this.__pushInstruction('setUserId', userId);
    } else {
      const bus = this.getWidgetsBus();
      const subscription = bus?.subscribe<any>(BUS_USER_INFO, (userInfo) => {
        if (!!userInfo?.mail) {
          this.logDebug(`Track user ${userId}`);
          this.__pushInstruction('setUserId', userId);
          bus?.unsubscribe(subscription);
        }
      });
    }
  }

  private __reactToUserConsent(consent: boolean): void {
    if (consent) {
      this.logDebug('User consents on Cookies usage.');
      this.__trackUser();
    } else {
      this.logDebug('User does not consent on Cookies usage.');
      // Clean Matomo cookies
      this.__pushInstruction('forgetCookieConsentGiven');
      // Add a custom dimension for SG Monitoring.
      this.__pushInstruction('setCustomDimension', 10, false);
    }
  }

  private async __manageUserConsent(): Promise<void> {
    const { disableUserTracking, userId } = this.configuration;
    return new Promise<void>((resolve: () => void, reject) => {
      // Do not block the tracking if we do not find the User Consent response on the sgwt-mini-footer widget...
      const timer = setTimeout(() => {
        this.logDebug('We are not able to handle the User Consent after 5 seconds, we go with Anonymous tracking.');
        this.__anonymousTracking();
        reject(new Error('No response after 5 seconds'));
      }, 5000);

      if (typeof disableUserTracking === 'undefined' || !disableUserTracking || !!userId) {
        // Case #1: we want the track user ID...
        this.logDebug('Tracking User ID, need to check the user consent on Cookies...');
        executeWhenCustomElementIsPresent(
          'sgwt-mini-footer',
          { documentDefinition: true, propName: 'getUserConsentOnMatomo' },
          async () => {
            const widget = document.querySelector<HTMLSgwtMiniFooterElement>('sgwt-mini-footer')!;

            // Manage the modification of the consent after initialization...
            widget.addEventListener('sgwt-mini-footer--user-consent-changed', () =>
              widget.getUserConsentOnMatomo().then((consent: boolean) => this.__reactToUserConsent(consent)),
            );

            // TODO Wait for ready?
            return widget
              .getUserConsentOnMatomo()
              .then((consent: boolean) => {
                this.__pushInstruction('requireCookieConsent');
                this.__reactToUserConsent(consent);
                clearTimeout(timer);
                return resolve();
              })
              .catch((err: Error) => reject(err));
          },
        );
      } else {
        // Case #2: we don't want the track user ID
        this.logDebug('Anonymous tracking...');
        this.__anonymousTracking();
        clearTimeout(timer);
        return resolve();
      }
    });
  }

  private async __addTracker(production: boolean) {
    window._paq = window._paq || [];
    if (window._paq.length !== 0) {
      this.logDebug('Web analytics already initialized.');
      return;
    }
    const fallbackBaseUrl = production ? MATOMO_URL_PRODUCTION : MATOMO_URL_HOMOLOGATION;
    const baseUrl = this.configuration.baseUrl ?? fallbackBaseUrl;
    this.__pushInstruction('setTrackerUrl', `${baseUrl}/sg-analytics.php`);
    this.__pushInstruction('setSiteId', this.configuration.siteId);
    try {
      await this.__manageUserConsent();
    } catch (_e) {
      // We dont care about the error
    }
    this.__pushInstruction('enableLinkTracking', true);
    this.__pushInstruction('trackPageView', true);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `${baseUrl}/sg-analytics.js`;
    document.body.appendChild(script);

    this.__observePageChange();

    startWidgetMonitoring(SgwtWidgetName.WEB_ANALYTICS, null, {
      siteId: this.configuration.siteId,
      disableUserTracking:
        typeof this.configuration.disableUserTracking !== 'undefined' && !!this.configuration.disableUserTracking,
      apmRumEnabled: this.configuration.apmRumEnabled,
      apmRumEnvironment: this.configuration.apmRumEnvironment,
      apmRumServiceName: this.configuration.apmRumServiceName,
    });

    this.emitEvent(`${SgwtWebAnalytics.is}--ready`);
  }

  private __track(type: trackType, data: any[], href = window.location.href, title = window.document.title) {
    this.logDebug(`Track ${type.replace('track', '')} ${data}, href: ${href}, title: ${title}`);
    this.__pushInstruction('setCustomUrl', href);
    this.__pushInstruction('setDocumentTitle', title);
    this.__pushInstruction(type, ...data);
  }

  private __initApmRum(
    serviceName: string | undefined,
    serverUrl: string | undefined,
    env: string | undefined,
  ): ApmBase | undefined {
    if (!serviceName || !serverUrl || apmBase.isActive()) {
      this.logDebug('No service-name or server-url defined. APM-RUM is disabled.');
      return undefined;
    }
    return init({ serviceName, serverUrl, environment: env || window.SGWTWidgetConfiguration.environment });
  }

  // ---------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------

  public get apm(): ApmBase | undefined {
    return this.__apm;
  }

  public trackEvent(category: string, action: string, name?: string, value?: string) {
    this.__track('trackEvent', [category, action, name, value]);
  }

  public trackSearch(keyword: string, category: string, count: number) {
    this.__track('trackSiteSearch', [keyword, category, count]);
  }

  public trackLink(href: string, linkType: string = 'link') {
    this.__track('trackLink', [href, linkType]);
  }

  public trackPageView(href?: string, title?: string) {
    this.__track('trackPageView', [], href, title);
  }

  /**
   * @deprecated use setCustomDimension instead.
   * https://matomo.org/faq/how-to/guide-to-using-custom-variables-deprecated/
   */
  public setCustomVariable(index: 1 | 2 | 3 | 4 | 5, name: string, value: any, scope?: 'visit' | 'page') {
    this.__pushInstruction('setCustomVariable', [index, name, value, scope]);
  }

  public setCustomDimension(index: number, value: any) {
    this.__pushInstruction('setCustomDimension', [index, value]);
  }
}

customElements.define('sgwt-web-analytics', SgwtWebAnalytics);
